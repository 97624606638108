import React, { useId } from "react";

interface InputInterface extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Input = ({ ...props }: InputInterface) => {
  const id = useId();

  return (
    <>
      {props.label && (
        <label htmlFor={id} className="block mb-2 text-sm font-medium tracking-wider">
          {props.label}
        </label>
      )}
      <input id={id} {...props} className="border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
    </>
  );
};
