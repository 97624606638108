"use client";
import clsx from "clsx";
import Image from "next/image";
import React, { useState } from "react";

import { StrapiImage } from "@/data/strapi";

interface CompanySlider {
  slides: CompanySlide[];
}

export interface CompanySlide {
  image: {
    data: StrapiImage;
  };
  title: string;
}

export const CompanySlider = ({ ...component }: CompanySlider) => {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const slide = component.slides[slideIndex];

  if (!slide) {
    return <></>;
  }

  return (
    <div className="w-full max-w-[1450px] mx-auto">
      <Image
        width={1000}
        height={1000}
        className="w-full h-[500px] object-cover"
        loader={(loader) => `${process.env.STRAPI_URL}${loader.src}`}
        src={slide.image.data.attributes.url}
        alt={slide.image.data.attributes.name ?? "company slide"}
      />

      <div className="flex justify-center">
        {component.slides.map((slide, index) => {
          const isActive = slideIndex === index;

          return (
            <>
              <div
                className={clsx("px-4 py-3 w-full text-center cursor-pointer text-kodi-blue text-lg md:block hidden", {
                  "border-t-4 border-kodi-magenta font-medium": isActive,
                  "border-t-4 border-white": !isActive,
                })}
                key={index}
                onClick={() => setSlideIndex(index)}
              >
                <p>{slide.title}</p>
              </div>
              <div className="px-1 py-2 md:hidden block">
                <div className="manual-carousel-slides">
                  <label
                    key={index}
                    htmlFor={`slideLabel${index}`}
                    onClick={() => setSlideIndex(index)}
                    className={clsx("manual-btn", { "bg-kodi-magenta": isActive })}
                  ></label>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
