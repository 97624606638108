"use client";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";

export interface StatisticWrapperInterface {
  image?: {
    data: StrapiImage;
  };
  title: string;
  subTitle: string;
}

export const StatisticWrapper = ({ ...component }: { items: StatisticWrapperInterface[] }) => {
  return (
    <div className="flex justify-between bg-ch21-gray py-5">
      {component.items?.map((item, index) => {
        return (
          <div className="mx-auto p-5" key={index}>
            <Image
              width={100}
              height={100}
              className="w-[10rem] mx-auto"
              loader={(loader) => process.env.STRAPI_URL + loader.src}
              src={item.image?.data.attributes.url as string}
              alt={item.image?.data.attributes.name ?? "statistic image"}
            />

            <div className="text-center mt-10">
              <p className="font-medium text-kodi-blue text-2xl">{item.title}</p>
              <p className="font-medium text-kodi-gray-600 mt-3">{item.subTitle}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
