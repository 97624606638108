import { redirect } from "next/navigation";
import React from "react";

import { addReceiver } from "@/components/Newsletter/components/Api";
import { NewsletterForm, NewsletterFormValuesInterface } from "@/components/Newsletter/components/Form";

export interface NewsletterInterface {
  title?: string;
  privacy_link?: string;
  searchParams?: unknown;
}

export const Newsletter = async (props: NewsletterInterface) => {
  if (isValidForRegistration(props.searchParams as NewsletterFormValuesInterface)) {
    const response = await addReceiver(props.searchParams as NewsletterFormValuesInterface);

    if (response) {
      redirect("/newsletter/success");
    }
  }

  return (
    <div className="max-w-[1500px] mx-auto py-5 px-5 lg:px-8">
      <div className="mb-10 mt-20">
        <div className="w-full mx-auto">
          <p className="text-3xl font-bold mb-5 uppercase">{props?.title}</p>

          <NewsletterForm privacy_link={props.privacy_link ?? "#!"} />
        </div>
      </div>
    </div>
  );
};

export const isValidForRegistration = (values: NewsletterFormValuesInterface) => {
  if (!validateEmail(values?.email)) {
    return false;
  }

  if (values?.birthday?.length) {
    if (!validateBirthday(values?.birthday)) {
      return false;
    }
  }

  if (values?.postalCode?.length) {
    if (!validatePostalCode(values?.postalCode)) {
      return false;
    }
  }

  return true;
};

const validateEmail = (email?: string) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const validateBirthday = (birthday?: string) => {
  return !!String(birthday)
    .toLowerCase()
    .match(/^(0[1-9]|[12][0-9]|3[01])\.?(0[1-9]|1[0-2])\.?(\d{4})$/);
};

const validatePostalCode = (postalCode?: string) => {
  const postal = parseInt(postalCode as string);

  return postal >= parseInt("01067") && postal <= 99998;
};
