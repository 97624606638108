import { Product, ProductCategory, ProductCollection, StoreGetProductsParams } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation";

import { DefaultFilter, SearchParams, SearchResultBody } from "@/actions/search";
import { isSortDirection, isString, query } from "@/data/Category";
import medusaRequest from "@/data/medusa-fetch";
import { medusaClient } from "@/lib/config";

export type ProductCategoryWithChildren = Omit<ProductCategory, "category_children"> & {
  category_children: ProductCategory[];
};

/**
 * Fetches a product by handle, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param handle (string) - The handle of the product to retrieve
 * @returns (array) - An array of products (should only be one)
 */
export async function getProductByHandle(handle: string): Promise<PricedProduct> {
  const { regions } = await medusaClient.regions.list();

  return medusaRequest("GET", "/products", {
    query: {
      handle: handle.toLowerCase(),
      region_id: regions?.at(0)?.id,
      expand: "categories,variants,variants.options,variants.images,options,options.values,images,collection,tags,type,profiles,variants.prices",
    },
  })
    .then((res) => res.body.products[0])
    .catch((err) => {
      throw err;
    });
}

/**
 * Fetches a list of products, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param pageParam (number) - The offset of the products to retrieve
 * @param queryParams (object) - The query parameters to pass to the API
 * @returns 'response' (object) - An object containing the products and the next page offset
 * @returns 'nextPage' (number) - The offset of the next page of products
 */
export async function getProductsList({ pageParam = 0, queryParams }: { pageParam?: number; queryParams: StoreGetProductsParams }): Promise<{
  response: { products: PricedProduct[]; count: number };
  nextPage: number;
}> {
  const limit = queryParams.limit || 12;

  const { products, count, nextPage } = await medusaRequest("GET", "/products", {
    query: {
      limit,
      offset: pageParam,
      ...queryParams,
    },
  })
    .then((res) => res.body)
    .catch((err) => {
      throw err;
    });

  return {
    response: { products, count },
    nextPage,
  };
}

/**
 * Fetches a list of collections, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param offset (number) - The offset of the collections to retrieve (default: 0
 * @returns collections (array) - An array of collections
 * @returns count (number) - The total number of collections
 */
export async function getCollectionsList(offset = 0): Promise<{ collections: ProductCollection[]; count: number }> {
  const { collections, count } = await medusaRequest("GET", "/collections", {
    query: {
      offset,
    },
  })
    .then((res) => res.body)
    .catch((err) => {
      throw err;
    });

  return {
    collections,
    count,
  };
}

/**
 * Fetches a collection by handle, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param handle  (string) - The handle of the collection to retrieve
 * @returns collections (array) - An array of collections (should only be one)
 * @returns response (object) - An object containing the products and the number of products in the collection
 * @returns nextPage (number) - The offset of the next page of products
 */
export async function getCollectionByHandle(handle: string): Promise<{
  collections: ProductCollection[];
  response: { products: Product[]; count: number };
  nextPage: number;
}> {
  return medusaRequest("GET", "/collections", {
    query: {
      handle: [handle],
    },
  })
    .then((res) => res.body)
    .catch((err) => {
      throw err;
    });
}

/**
 * Fetches a list of products in a collection, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param pageParam (number) - The offset of the products to retrieve
 * @param handle (string) - The handle of the collection to retrieve
 * @param cartId (string) - The ID of the cart
 * @returns response (object) - An object containing the products and the number of products in the collection
 * @returns nextPage (number) - The offset of the next page of products
 */
export async function getProductsByCollectionHandle({ pageParam = 0, handle, cartId }: { pageParam?: number; handle: string; cartId?: string }): Promise<{
  response: { products: PricedProduct[]; count: number };
  nextPage: number;
}> {
  const { id } = await getCollectionByHandle(handle).then((res) => res.collections[0]);

  const { response, nextPage } = await getProductsList({
    pageParam,
    queryParams: { collection_id: [id], cart_id: cartId },
  })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });

  return {
    response,
    nextPage,
  };
}

/**
 * Fetches a list of categories, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param offset (number) - The offset of the categories to retrieve (default: 0
 * @param limit (number) - The limit of the categories to retrieve (default: 100)
 * @returns product_categories (array) - An array of product_categories
 * @returns count (number) - The total number of categories
 * @returns nextPage (number) - The offset of the next page of categories
 */
export async function getCategoriesList(
  offset = 0,
  limit?: number,
): Promise<{
  product_categories: ProductCategoryWithChildren[];
  count: number;
}> {
  const { product_categories, count } = await medusaRequest("GET", "/product-categories", {
    query: {
      offset,
      limit,
    },
  })
    .then((res) => res.body)
    .catch((err) => {
      throw err;
    });

  return {
    product_categories,
    count,
  };
}

/**
 * Fetches a category by handle, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param categoryHandle  (string) - The handle of the category to retrieve
 * @returns collections (array) - An array of categories (should only be one)
 * @returns response (object) - An object containing the products and the number of products in the category
 * @returns nextPage (number) - The offset of the next page of products
 */
export async function getCategoryByHandle(categoryHandle: string[]): Promise<{
  product_categories: ProductCategoryWithChildren[];
}> {
  const handles = categoryHandle.map((handle: string, index: number) => categoryHandle.slice(0, index + 1).join("/"));

  const productCategories = [] as ProductCategoryWithChildren[];

  for (const handle of handles) {
    await medusaRequest("GET", "/product-categories", {
      query: {
        handle,
      },
    })
      .then(({ body }) => {
        productCategories.push(body.product_categories[0]);
      })
      .catch((err) => {
        throw err;
      });
  }

  return {
    product_categories: productCategories,
  };
}

export interface campaignProductsRequestInterface {
  campaignId: string;
  pageParam?: number;
  cartId?: string;
  limit?: number;
  offset?: number;
}

export interface categoryProductsRequestInterface {
  handle: string;
  pageParam?: number;
  pageParams?: {
    number: number;
  };
  cartId?: string;
  limit?: number;
  searchParams?: ReadonlyURLSearchParams;
}

export async function getCategoryPathByHandle(handle: string): Promise<string[]> {
  let path: string[] = [];

  const { name: categoryName, parent_category } = (await getCategoryByHandle([handle])).product_categories[0];

  path.unshift(categoryName);
  if (parent_category?.name) {
    path = [...(await getCategoryPathByHandle(parent_category.handle)), ...path];
  }

  return path;
}

/**
 * Fetches a list of products in a collection, using the Medusa API or the Medusa Product Module, depending on the feature flag.
 * @param pageParams (object) - PageParameters of the currentPage
 * @param handle (string) - The handle of the collection to retrieve
 * @param limit (number) - The limit of the products to retrieve (default: 12)
 * @param searchParams (ReadonlyURLSearchParams) - The search parameters omitted from the page request
 * @returns response (object) - An object containing the products and the number of products in the collection
 * @returns nextPage (number) - The offset of the next page of products
 */
export async function getProductsByCategoryHandle({
  pageParams,
  handle,
  limit = 12,
  searchParams = new ReadonlyURLSearchParams(),
}: categoryProductsRequestInterface): Promise<SearchResultBody> {
  const categoryPath = await getCategoryPathByHandle(handle);
  const offset = pageParams ? (pageParams.number * limit - limit ?? undefined) : undefined;

  return query({
    displayName: categoryPath[categoryPath.length - 1],
    searchParams: searchParams,
    predefinedFilter: "categoryROOT" + (categoryPath.length > 1 ? "/" + categoryPath.slice(0, -1).join("/") : ""),
    limit,
    offset,
  })
    .then((res) => res.body)
    .catch((err) => {
      throw err;
    });
}

export function parseFilterParams(searchParams: ReadonlyURLSearchParams) {
  return Array.from(searchParams.entries())
    .filter(([key]) => key.startsWith("filter"))
    .reduce(
      (acc, [key, value]) => {
        const [, index, param] = key.match(/filter\[(\d+)]\[(\w+)]/) || [];
        if (!index || !param) {
          return acc;
        }

        const idx = parseInt(index, 10);
        if ("undefined" === typeof acc[idx]) {
          acc[idx] = {} as {
            attribute: string;
            type: string;
            values: string[];
            min: number;
            max: number;
          };
        }

        if (param === "values") {
          if (!Array.isArray(acc[idx][param])) {
            acc[idx][param] = [];
          }
          acc[idx][param].push(value);
        } else if (param === "min" && value != "0") {
          acc[idx]["min"] = parseFloat(value);
        } else if (param === "max" && value != "0") {
          acc[idx]["max"] = parseFloat(value);
        } else if (param === "type" || param === "attribute") {
          acc[idx][param] = value;
        }

        return acc;
      },
      [] as Array<{ attribute: string; type: string; values: string[]; min: number; max: number }>,
    );
}

export function addFilterAndSortToSearchParams(params: SearchParams, searchParams: ReadonlyURLSearchParams | null, filter: DefaultFilter[]) {
  params.filter = filter;
  if (searchParams && searchParams.size > 0) {
    const searchFilter = parseFilterParams(searchParams);
    params.filter = [...filter, ...searchFilter] as unknown as DefaultFilter[];

    const sortField = searchParams.get("sortField");
    const sortDirection = searchParams.get("sort");
    const sort = [];

    if (sortField && isString(sortField) && sortDirection && isSortDirection(sortDirection)) {
      sort.push({
        field: sortField,
        direction: sortDirection,
      });
    }

    if (sort.length > 0) {
      params.sort = sort;
    }
  }
}
