"use client";

import clsx from "clsx";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";

export interface ImageTextColProps {
  title?: string;
  image?: {
    data: StrapiImage;
  };
  text?: string;
  isContentCentered: boolean;
}

export const ImageTextCol = ({ ...component }: ImageTextColProps) => {
  return (
    <div className={clsx("flex flex-col max-w-[400px]", component.isContentCentered ? "items-center" : "items-start")}>
      <Image
        width={512}
        height={288}
        className="w-full"
        loader={(loader) => process.env.STRAPI_URL + loader.src}
        src={component.image?.data?.attributes?.url as string}
        alt={component.image?.data?.attributes.name ?? "Image Preview"}
      />
      {component.title && <span className="text-xl font-bold text-kodi-blue mb-3">{component.title ?? ""}</span>}
      <div
        className={clsx("text-kodi-gray-600 text-lg", component.isContentCentered ? "text-center" : "text-left")}
        dangerouslySetInnerHTML={{ __html: component.text ?? "" }}
      />
    </div>
  );
};
