"use client";

import "moment/locale/de";

import moment from "moment/moment";
import Link from "next/link";
import { useRouter } from "next/navigation";
import qs from "qs";
import React, { useEffect, useState } from "react";

import { DropdownButton } from "@/components/Button/DropdownButton";
import { Input } from "@/components/Form/input";
import { isValidForRegistration } from "@/components/Newsletter";

enum GenderEnum {
  "m" = "Herr",
  "f" = "Frau",
}

const populateDays = (dayCount: number): string[] => {
  const days: string[] = [];
  for (let i = 1; i <= dayCount; i++) {
    days.push(i.toString().padStart(2, "0"));
  }
  return days;
};

const populateYears = (): string[] => {
  const years: string[] = [];
  for (let i = new Date().getFullYear(); i >= 1920; i--) {
    years.push(i.toString());
  }
  return years;
};

export interface NewsletterFormValuesInterface {
  email: string;
  gender: string;
  firstName: string;
  lastName: string;
  birthday: string;
  postalCode: string;
}

const formValuesPayload = (email: string, gender: string, firstName: string, lastName: string, birthday: string, postalCode: string) =>
  ({
    email: email,
    gender: gender,
    firstName: firstName,
    lastName: lastName,
    birthday: birthday,
    postalCode: postalCode,
  }) as NewsletterFormValuesInterface;

export const NewsletterForm = ({ privacy_link }: { privacy_link: string }) => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("01");
  const [dayCount, setDayCount] = useState<number>(31);
  const [birthMonth, setBirthMonth] = useState<string>("01");
  const [birthYear, setBirthYear] = useState<string>(new Date().getFullYear().toString());
  const [postalCode, setPostalCode] = useState<string>("");
  const [selectedGender, setSelectedGender] = useState<string>("");
  const [isChecked, setChecked] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const router = useRouter();

  moment.locale("de");

  useEffect(() => {
    const daysInMonth = moment(`${birthYear}-${birthMonth}`, "YYYY-MM").daysInMonth();
    setDayCount(daysInMonth);
  }, [birthYear, birthMonth]);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const payload = formValuesPayload(email, selectedGender, firstName, lastName, `${birthday}.${birthMonth}.${birthYear}`, postalCode);

    if (!isChecked) {
      setError("Bitte bestätigen Sie die Datenschutzerklärung");
      return;
    }

    if (isValidForRegistration(payload)) {
      setError("");
      router.push(qs.stringify(payload, { addQueryPrefix: true }), { scroll: false });
      return;
    }

    setError("Bitte überprüfen Sie die Eingabe Ihrer Felder.");
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-4 select-none">
        <div className="col-span-12 lg:col-span-6">
          <Input label="E-Mail*" type="email" placeholder="E-Mail" required onChange={(event) => setEmail(event.target.value)} />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <label htmlFor="gender" className="block mb-2 text-sm font-medium tracking-wider">
            Anrede
          </label>
          <DropdownButton
            closeOnClick={true}
            title={!selectedGender?.length ? "Anrede" : selectedGender}
            className="text-black pt-3 pb-3 w-full"
            colorClasses="bg-gray-100 hover:bg-gray-200"
          >
            {Object.values(GenderEnum).map((gender, index) => {
              return (
                <li className="block px-4 py-2 hover:bg-gray-300 font-bold rounded-lg" onClick={() => setSelectedGender(gender)} key={index}>
                  {gender}
                </li>
              );
            })}
          </DropdownButton>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Input label="Vorname" type="text" placeholder="Vorname" onChange={(event) => setFirstName(event.target.value)} />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Input label="Nachname" type="text" placeholder="Nachname" onChange={(event) => setLastName(event.target.value)} />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <label htmlFor="birthdate" className="block mb-2 text-sm font-medium">
            Geburtsdatum
          </label>
          <div className="flex flex-row gap-4">
            <DropdownButton closeOnClick={true} title={birthday} className="text-black pt-3 pb-3 w-full" colorClasses="bg-gray-100 hover:bg-gray-200">
              {populateDays(dayCount).map((day, index) => (
                <li className="block px-4 py-2 hover:bg-gray-300 font-bold rounded-lg" onClick={() => setBirthday(day)} key={index}>
                  {day}
                </li>
              ))}
            </DropdownButton>
            <DropdownButton
              closeOnClick={true}
              title={moment.months()[parseInt(birthMonth) - 1]}
              className="text-black pt-3 pb-3 w-full"
              colorClasses="bg-gray-100 hover:bg-gray-200"
            >
              {moment.months().map((month, index) => (
                <li
                  className="block px-4 py-2 hover:bg-gray-300 font-bold rounded-lg"
                  onClick={() => setBirthMonth((index + 1).toString().padStart(2, "0"))}
                  key={index}
                >
                  {month}
                </li>
              ))}
            </DropdownButton>
            <DropdownButton closeOnClick={true} title={birthYear} className="text-black pt-3 pb-3 w-full" colorClasses="bg-gray-100 hover:bg-gray-200">
              {populateYears().map((year, index) => (
                <li className="block px-4 py-2 hover:bg-gray-300 font-bold rounded-lg" onClick={() => setBirthYear(year)} key={index}>
                  {year}
                </li>
              ))}
            </DropdownButton>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Input
            label="Postleitzahl"
            type="text"
            inputMode="numeric"
            placeholder="Postleitzahl"
            onChange={(event) => setPostalCode(event.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key) || postalCode.length === 5) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>

      <div className="flex items-center mb-2 mt-5">
        <input
          id="default-checkbox"
          type="checkbox"
          value=""
          checked={isChecked}
          onClick={() => setChecked(!isChecked)}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
        />
        <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900">
          Ich möchte den KODi-Newsletter erhalten und akzeptiere die{" "}
          <Link href={privacy_link} className="text-kodi-blue hover:text-kodi-blue-hover underline">
            Datenschutzerklärung
          </Link>
          .*
        </label>
      </div>

      {isError.length ? <p className="text-red-600 mt-3">{isError}</p> : null}

      <button className="bg-kodi-blue text-white hover:bg-kodi-blue-hover py-2 px-5 rounded-lg mt-2" onClick={(event) => handleSubmit(event)}>
        Abonnieren
      </button>
    </div>
  );
};
