"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";

import { ColumnEnum } from "@/components/(company)/Information";
import { StrapiImage } from "@/data/strapi";

export interface InformationCardInterface {
  image: {
    data: StrapiImage;
  };
  title: string;
  description?: string;
  link?: string;
  onClick?: () => void;
}

export const InformationCard = ({ ...component }: InformationCardInterface & { columns: ColumnEnum }) => {
  const router = useRouter();

  const handleClick = () => {
    if (component?.link) {
      router.push(component.link);
    }
  };

  return (
    <div className="flex flex-col cursor-pointer max-w-[600px] md:max-w-[720px] h-full justify-between" onClick={() => handleClick()}>
      <div className="text-2xl font-bold text-kodi-blue break-normal">{component.title}</div>
      <Image
        width={600}
        height={332}
        className="my-2"
        onClick={component.onClick}
        loader={(loader) => `${process.env.STRAPI_URL}${loader.src}`}
        src={component?.image?.data?.attributes?.url}
        alt={component?.image?.data?.attributes?.name ?? "Information Preview"}
      />
      <div className="flex-grow">{component?.description && <div dangerouslySetInnerHTML={{ __html: component.description }} />}</div>
      <div className="mt-auto">{component?.link && <span className="text-kodi-blue underline text-lg">Mehr erfahren</span>}</div>
    </div>
  );
};
