"use client";

import clsx from "clsx";
import React from "react";

import { CommonInterface, ImagePreviewInterface, useImageSrcSet } from "@/hook/ImageSrcSetHook";
import { ImageInterface } from "@/interfaces/StrapiInterfaces";

interface ImageBlockInterface {
  image?: ImageInterface;
  imageTablet?: ImageInterface;
  imageMobile?: ImageInterface;
  imageTitle?: string;
  shadow?: boolean;
}

export const ImageBlock = ({ ...component }: ImageBlockInterface) => {
  const { preview, getImageConfiguration, getImage } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  if (!component.image) {
    return null;
  }

  const { image, imageTablet, imageMobile, imageTitle = "Bild Block" } = component;
  const defaultImage = getImage(image) as string;

  const prepareCommon: CommonInterface = {
    title: imageTitle,
    alt: image?.data?.attributes.name ?? imageTitle,
  };

  const prepareImageConfiguration: ImagePreviewInterface = getImageConfiguration({
    desktop: defaultImage,
    tablet: getImage(imageTablet) ?? defaultImage,
    mobile: getImage(imageMobile) ?? defaultImage,
  });

  const { desktop, tablet, mobile } = preview(prepareCommon, prepareImageConfiguration);

  return (
    <div className="max-w-[1500px] mx-auto">
      <div className="mb-10">
        <picture>
          <source media="(min-width: 1024px)" srcSet={desktop} />
          <source media="(min-width: 768px)" srcSet={tablet} />
          <img
            {...mobile}
            style={{ objectFit: "cover" }}
            className={clsx("w-full", { "shadow-[2px_17px_5px_1px_rgba(0,0,0,0.25)]": component.shadow })}
            alt={prepareCommon.alt}
          />
        </picture>
      </div>
    </div>
  );
};
