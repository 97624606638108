"use client";

import clsx from "clsx";

interface TextBlockInterface {
  text?: string;
  noPadding?: boolean;
}

export const TextBlock = ({ ...component }: TextBlockInterface) => {
  if (!component.text) {
    return null;
  }

  const noPadding = component.noPadding;

  return (
    <div
      className={clsx("max-w-[1500px] mx-auto py-5", {
        "px-5 lg:px-8": !noPadding,
      })}
    >
      <div className={clsx("mb-10", { "mx-10 lg:mx-20": !noPadding })} dangerouslySetInnerHTML={{ __html: component?.text }} />
    </div>
  );
};
