import { getImageProps } from "next/image";

import { StrapiImage } from "@/data/strapi";
import { ImageInterface } from "@/interfaces/StrapiInterfaces";

export interface CommonInterface {
  title: string;
  alt: string;
}

export interface ImagePreviewInterface {
  desktop: string;
  tablet: string;
  mobile: string;
}

export const useImageSrcSet = ({ host }: { host: string }) => {
  const imageProps = (width: number, height: number, quality: number, src: string, common: CommonInterface & { priority?: boolean }) => {
    return getImageProps({
      ...common,
      width: width,
      height: height,
      quality: quality,
      src: src,
    });
  };

  const previews = (common: CommonInterface, configuration: ImagePreviewInterface) => {
    const {
      props: { srcSet: desktop },
    } = imageProps(1440, 875, 80, `${host + configuration.desktop}`, common);
    const {
      props: { srcSet: tablet },
    } = imageProps(750, 1334, 70, `${host + configuration.tablet}`, common);
    const {
      props: { ...mobile },
    } = imageProps(750, 1334, 80, `${host + configuration.mobile}`, { ...common, priority: true });

    return {
      desktop: desktop,
      tablet: tablet,
      mobile: mobile,
    };
  };

  const getImageConfiguration = ({ desktop, tablet, mobile }: ImagePreviewInterface) => {
    return {
      desktop: desktop,
      tablet: tablet,
      mobile: mobile,
    };
  };

  const preview = (common: CommonInterface, images: ImagePreviewInterface) => {
    return previews(common, images);
  };

  const getImage = (image: { data: StrapiImage } | ImageInterface | undefined): string | undefined => {
    return image?.data?.attributes.url;
  };

  return { preview, getImageConfiguration, getImage };
};
