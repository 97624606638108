"use client";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";

interface ImageTextBlockInterface extends TextInterface {
  image?: {
    data: StrapiImage;
  };
  isRightAlign: boolean;
}

interface TextInterface {
  title: string;
  text?: string;
}

export const ImageTextBlock = ({ ...component }: ImageTextBlockInterface) => {
  return (
    <div className="grid grid-cols-12 gap-10 my-5">
      <div className="col-span-12 lg:col-span-6 my-auto">{component.isRightAlign ? <ImageComponent {...component} /> : <TextComponent {...component} />}</div>
      <div className="col-span-12 lg:col-span-6 my-auto">{component.isRightAlign ? <TextComponent {...component} /> : <ImageComponent {...component} />}</div>
    </div>
  );
};

const TextComponent = ({ title, text }: TextInterface) => {
  return (
    <>
      <p className="text-xl font-medium text-kodi-blue mb-3">{title}</p>
      <div className="text-kodi-gray-600" dangerouslySetInnerHTML={{ __html: text ?? "" }} />
    </>
  );
};

const ImageComponent = ({ image }: { image?: { data: StrapiImage } }) => {
  return (
    <Image
      width={100}
      height={100}
      className="w-full"
      loader={(loader) => process.env.STRAPI_URL + loader.src}
      src={image?.data?.attributes?.url as string}
      alt={image?.data?.attributes.name ?? "Image Preview"}
    />
  );
};
