export type NumberOfCols = 1 | 2 | 3 | 4 | 5 | 6;
export type ColsNumbers = 0 | 1 | 2 | 3 | 4 | 5;

export const colSpanMap: Record<NumberOfCols, string> = {
  1: "col-span-12",
  2: "col-span-12 md:col-span-6",
  3: "col-span-12 sm:col-span-6 md:col-span-4",
  4: "col-span-12 sm:col-span-6 lg:col-span-3",
  5: "col-span-6 sm:col-span-4 lg:col-span-2",
  6: "col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2",
};

export const colSpans: Record<ColsNumbers, string> = {
  0: "col-span-12",
  1: "col-span-12 md:col-span-4 lg:col-span-6",
  2: "col-span-12 lg:col-span-4 md:col-span-6",
  3: "col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-4",
  4: "col-span-12 md:col-span-4 lg:col-span-2",
  5: "col-span-12 md:col-span-4 lg:col-span-1",
};
