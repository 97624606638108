"use client";

import clsx from "clsx";

import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";

export interface CollapseInterface {
  title: string;
  text: string;
}

export const Collapse = ({ title, text, isSelected, onClick }: CollapseInterface & { isSelected: boolean; onClick: () => void }) => {
  return (
    <div id="accordion-collapse" data-accordion="collapse" className="transition">
      <div id="accordion-collapse-heading-1">
        <div
          onClick={onClick}
          className="flex items-center justify-between cursor-pointer w-full rtl:text-right border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 hover:bg-gray-100"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <p className={clsx("font-medium duration-150 px-4 py-0.5", { "text-gray-500": !isSelected, "text-kodi-blue": isSelected })}>{title}</p>
          <ArrowDropUpIcon className={clsx("w-7 h-7 shrink-0 duration-150", { "rotate-180": isSelected })} />
        </div>
      </div>
      <div id="accordion-collapse-body-1" className={clsx("duration-150", { hidden: !isSelected })} aria-labelledby="accordion-collapse-heading-1">
        <div className="border border-b-0 border-gray-200 p-4" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
};
