import { ProductCategory } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { useCart } from "medusa-react";
import { useEffect, useState } from "react";

import { PriceType } from "@/components/Product/components/ProductPrice";
import { medusaClient } from "@/lib/config";

export const useSaleProducts = (category: ProductCategory | null, totals: number = 5) => {
  const { cart } = useCart();
  const [products, setProducts] = useState<PricedProduct[]>([]);
  const [isShowMoreProducts, setShowMoreProducts] = useState<boolean>(false);
  const [_offset, _setOffset] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const limit = 25;

  useEffect(() => {
    if (category && cart?.region_id) {
      filterProducts(0);
    }
  }, [category, cart]);

  const filterProducts = (offset: number) => {
    setLoading(true);

    fetchProducts(offset)
      .then(({ products }) => {
        if (products?.length) {
          const saleProducts = filterSaleProducts(products);

          if (saleProducts.length) {
            setShowMoreProducts(true);
            handleProducts(saleProducts);
          }

          if (saleProducts.length < totals) {
            _setOffset(offset + limit);
            filterProducts(offset + limit);
          }
        } else {
          setShowMoreProducts(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleProducts = (saleProducts: (PricedProduct | undefined)[]) => {
    const _products: PricedProduct[] = [...products];

    for (let i = 0; i < totals - _products.length; i++) {
      const saleProduct = saleProducts.at(i);

      if (saleProduct) {
        _products.push(saleProduct);
      }
    }

    setProducts(_products);
  };

  const filterSaleProducts = (products: PricedProduct[]) => {
    return products
      ?.map((product) => {
        if (!product.variants?.find((variant) => variant.calculated_price_type === PriceType.sale)) {
          return null;
        }

        return product;
      })
      .filter((product) => product !== null);
  };

  const fetchProducts = async (offset: number = 0) => {
    return await medusaClient.products.list({
      category_id: [category!.id],
      expand: "variants,variants.prices",
      offset: offset,
      limit: limit,
      region_id: cart?.region_id,
    });
  };

  const fetchMoreProducts = () => {
    if (category) {
      filterProducts(_offset);
    }
  };

  return { products, isShowMoreProducts, fetchMoreProducts, isLoading };
};
