"use client";

import clsx from "clsx";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";

interface BrandSectionInterface {
  title: string;
  subTitle?: string;
  items: BrandSectionItem[];
}

interface BrandSectionItem {
  image: {
    data: StrapiImage;
  };
  text?: string;
}

export const BrandSection = ({ ...component }: BrandSectionInterface) => {
  return (
    <div className="my-10">
      <div className={clsx("text-2xl font-medium text-kodi-blue whitespace-nowrap", { "mb-3": !component.subTitle?.length })}>{component.title}</div>
      {component.subTitle && <div className="mb-10" dangerouslySetInnerHTML={{ __html: component.subTitle }} />}

      <div className="grid grid-cols-12 gap-5">
        {component.items.map((item, index) => (
          <BrandItem {...item} key={index} />
        ))}
      </div>
    </div>
  );
};

const BrandItem = ({ image, text }: BrandSectionItem) => {
  return (
    <div
      className={clsx("mx-auto col-span-12 md:col-span-4 lg:col-span-2", {
        "text-left": text?.length,
        "text-center": !text?.length,
      })}
    >
      <Image
        src={image.data.attributes.url}
        alt={image.data.attributes.name}
        width={100}
        height={100}
        loader={(loader) => process.env.STRAPI_URL + loader.src}
        className="w-full"
      />

      {text?.length && <div className="mt-6" dangerouslySetInnerHTML={{ __html: text }} />}
    </div>
  );
};
