import { ProductCategory } from "@medusajs/medusa";
import React from "react";

import { ScrollContainer } from "@/components/Scroll/Container";
import { SliderProduct } from "@/components/Slider/Product/components/SlideProduct";
import { LoadingSpinner } from "@/components/Spinner/LoadingSpinner";
import { useSaleProducts } from "@/hook/product/sale-products";

export const CategoryOfferProducts = ({ category }: { category: ProductCategory | null }) => {
  const { products } = useSaleProducts(category);

  if (!category) {
    return (
      <div className="flex justify-center items-center h-full">
        <LoadingSpinner isShow={!category} />
      </div>
    );
  }

  return (
    <div className={"relative w-full overflow-y-auto scrollbar-width px-2 md:px-0 md:pr-2 py-2 2xl:py-0"}>
      <ScrollContainer>
        <>
          {products?.map((product, index) => {
            return <SliderProduct product={product} key={index} isFirstItem={index === 0} isLastItem={index + 1 < products.length} />;
          })}
        </>
      </ScrollContainer>
    </div>
  );
};
