import { ProductCategory } from "@medusajs/medusa";
import React, { useEffect, useState } from "react";

import { CategoryOfferProducts } from "@/components/Slider/Product/components/Types/CategoryOfferProducts";
import { ThemeWorld } from "@/components/Slider/Product/components/Types/ThemeWorld";
import { WeeklyOfferProducts } from "@/components/Slider/Product/components/Types/WeeklyOfferProducts";
import { getCategoryByHandle } from "@/data/Category";

export enum ProductSliderType {
  "weekly_offers" = "Wochenangebot",
  "category_offers" = "Kategorieangebot",
  "theme_world" = "Themenwelt",
}

export const ContentManager = ({ type, categoryName, medusa_key }: { type?: string; categoryName?: string; medusa_key?: string }) => {
  const [category, setCategory] = useState<ProductCategory | null>(null);

  useEffect(() => {
    if (categoryName?.length) {
      getCategoryByHandle(categoryName.toLowerCase() ?? "").then((response) => {
        setCategory(response.product_categories?.at(0));
      });
    }
  }, [categoryName]);

  switch (type) {
    case ProductSliderType.weekly_offers:
      return <WeeklyOfferProducts />;
    case ProductSliderType.category_offers:
      return <CategoryOfferProducts category={category} />;
    case ProductSliderType.theme_world:
      return medusa_key?.length ? <ThemeWorld type={medusa_key as string} /> : <></>;
    default:
      return <></>;
  }
};
