"use client";

import { useState } from "react";

import { Collapse, CollapseInterface } from "@/components/(company)/Collapse";

interface QuestionInterface {
  collapses: CollapseInterface[];
}

export const Questions = ({ collapses }: QuestionInterface) => {
  const [selectedCollapse, setSelectedCollapse] = useState<CollapseInterface | null>(null);

  return (
    <div className="my-10">
      {collapses.map((collapse, index) => {
        return (
          <Collapse
            isSelected={selectedCollapse === collapse}
            onClick={() => setSelectedCollapse(selectedCollapse === collapse ? null : collapse)}
            key={index}
            {...collapse}
          />
        );
      })}
    </div>
  );
};
