"use client";

interface CustomerListInterface {
  title: string;
  smallText?: string;
  customerItem: CustomerItemInterface[];
}

export interface CustomerItemInterface {
  text: string;
  index: number;
}

export const CustomerList = ({ ...component }: CustomerListInterface) => {
  return (
    <div className="max-w-[1500px] mx-0 sm:mx-10 md:mx-auto py-5 px-5 lg:px-8">
      <div className="flex gap-x-10 items-start flex-wrap">
        <div className="text-2xl text-kodi-magenta md:text-kodi-blue font-medium">{component.title}</div>
        <div className="my-auto mt-1.5">
          {component.customerItem?.map(({ text }, index) => {
            return <CustomerItem text={text} index={index + 1} key={index} />;
          })}

          {component.smallText?.length && <div className="text-xs mt-10">{component.smallText}</div>}
        </div>
      </div>
    </div>
  );
};

const CustomerItem = ({ text, index }: CustomerItemInterface) => {
  return (
    <div className="flex gap-x-2 mb-3">
      <div className="bg-kodi-yellow rounded-full my-auto">
        <span className="font-bold text-red-600 p-1.5">{index}.</span>
      </div>
      <div className="my-auto inset-0 space-y-0">
        <span className="text-xs text-kodi-blue">{text}</span>
      </div>
    </div>
  );
};
