"use client";

import Image from "next/image";
import React from "react";

import { Button } from "@/components/(company)/Button";
import { ItemImageInterface } from "@/components/(company)/Slider/ItemImageSlider";
import Modal from "@/components/Modal";

export interface ModalSettingsInterface {
  headline?: string;
  subHeadline?: string;
  button?: string;
  buttonLink?: string;
}

export const ImageDetail = ({
  isOpen,
  onClose,
  item,
}: {
  isOpen: boolean;
  onClose: () => void;
  item: (ItemImageInterface & ModalSettingsInterface) | null;
}) => {
  return (
    <Modal isOpen={isOpen} close={onClose} size={"extra-large"} className="!p-0">
      <Modal.Title>
        <div className="my-2 mx-5">
          {item?.headline && <p className="text-base text-kodi-gray-600">{item.headline}</p>}
          {item?.subHeadline && <p className="text-xl font-bold text-kodi-blue">{item.subHeadline}</p>}
        </div>
      </Modal.Title>
      <Modal.Body>
        <Image
          width={100}
          height={100}
          className="w-full h-full"
          loader={(loader) => process.env.STRAPI_URL + loader.src}
          src={item?.image?.data.attributes.url ?? ""}
          alt={item?.image?.data.attributes.name ?? "slider image"}
        />

        {item?.title && <p className="absolute bottom-0 bg-white/75 w-full text-center py-1">{item?.title}</p>}
      </Modal.Body>
      <Modal.Footer>
        {item?.button && item?.buttonLink && (
          <div className="flex justify-end m-5">
            <Button name={item.button} link={item.buttonLink} target={"_self"} onClick={onClose} />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};
